// ✅ frontend/src/components/useMarkerFilter.js

import { useEffect } from 'react';
import L from 'leaflet';

const useMarkerFilter = ({ map, locations, showDumps, showRecyclingPoints, userEmail, getCookie }) => {
  useEffect(() => {
    if (!map || !userEmail) return; // 💡 ждём пока имя пользователя загрузится

    window.map = map;
    window.locations = locations;

    // Удаляем старые маркеры
    map.eachLayer(layer => {
      if (layer.options && layer.options.pane === 'markerPane') {
        map.removeLayer(layer);
      }
    });

    const markers = [];

    locations.forEach((location) => {
      const lat = parseFloat(location.latitude);
      const lng = parseFloat(location.longitude);
      if (isNaN(lat) || isNaN(lng)) return;

      if (location.type === 'waste_dump' && !showDumps) return;
      if (location.type === 'recycling_point' && !showRecyclingPoints) return;

      const icon = location.type === 'recycling_point'
        ? L.icon({
            iconUrl: '/icons/recycling_marker_40x40.png',
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [0, -40],
          })
        : L.icon({
            iconUrl: '/icons/dump_marker_40x40.png',
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [0, -40],
          });

      const popupContent = `
        <div style="max-width: 250px;">
          <b>Тип:</b> ${location.type || 'Не указан'}<br/>
          <b>Адрес:</b> ${location.address || 'Неизвестен'}<br/>
          <b>Фото:</b> ${location.photos?.length || 0}<br/>
          <b>Пользователей:</b> ${new Set(location.photos?.map(p => p.uploaded_by)).size || 0}<br/>
          <br/>
          <a href="/location/${location.id}" target="_blank" style="text-decoration: none; color: #2e8bff;">
            🔍 Открыть локацию
          </a>
        </div>
      `;



      const isAuthor = location.created_by_email === userEmail;
      const hasOnlyOwnPhotos = location.photos?.every(photo => photo.uploaded_by_email === userEmail);
      const canDrag = isAuthor && hasOnlyOwnPhotos;

      // 🔍 Лог для отладки
      console.log(
  `🔍 [${location.id}] Проверка перетаскивания маркера:`,
  `\n   Автор локации: ${location.created_by_email}`,
  `\n   Email пользователя: ${userEmail}`,
  `\n   Совпадает? → ${isAuthor}`,
  `\n   Все фото — пользователя? → ${hasOnlyOwnPhotos}`,
  `\n   ➤ canDrag: ${canDrag}`
);

      console.log(
  `[${location.id}] Фото загружены пользователями:`,
  location.photos?.map(p => `${p.uploaded_by} (${p.uploaded_by_email})`)
);


      const marker = L.marker([lat, lng], {
        icon,
        draggable: canDrag,
      }).addTo(map).bindPopup(popupContent);

      markers.push(marker);

      if (canDrag) {
        marker.on('dragend', async (event) => {
          const newCoords = event.target.getLatLng();
          const csrftoken = getCookie('csrftoken');
          try {
            const response = await fetch(`https://ecomaner.com/api/map/locations/${location.id}/`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
              },
              credentials: 'include',
              body: JSON.stringify({
                latitude: newCoords.lat,
                longitude: newCoords.lng
              })
            });

            if (!response.ok) {
              console.error('Ошибка обновления координат:', response.statusText);
            }
          } catch (err) {
            console.error('Ошибка при PATCH:', err);
          }
        });
      }
    });

    window.markers = markers;

  }, [map, locations, showDumps, showRecyclingPoints, userEmail]);
};

export default useMarkerFilter;
