import React, { useState, useEffect, useRef } from 'react';

function NotificationsDropdown() {
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (!token) {
      console.warn("❌ Токен отсутствует — уведомления не загрузятся");
      return;
    }

    console.log("📥 Загружаем уведомления, токен:", token);

    fetch('/api/notifications/', {
      headers: {
        Authorization: 'Token ' + token
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log("✅ Загружены уведомления:", data);
        setNotifications(data);
      })
      .catch(err => {
        console.error("⛔ Ошибка загрузки уведомлений:", err);
      });
  }, [token]);

  useEffect(() => {
    if (!token) {
      console.warn("❌ Токен отсутствует — WebSocket не запускается");
      return;
    }

    const wsUrl = `wss://${window.location.host}/ws/notifications/`;
    console.log("🌐 Подключение к WebSocket:", wsUrl);
    const socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log("🟢 WebSocket подключен");
    };

    socket.onmessage = (event) => {
      console.log("📨 Новое сообщение по WebSocket:", event.data);

      const data = JSON.parse(event.data);
      if (data.type === "new_notification") {
        setNotifications(prev => [
          {
            id: Date.now(),
            message: data.message,
            is_read: false,
            created_at: new Date().toISOString()
          },
          ...prev
        ]);
      }
    };

    socket.onclose = (e) => {
      console.warn("🔌 WebSocket закрыт:", e.code, e.reason);
    };

    socket.onerror = (err) => {
      console.error("🔥 WebSocket ошибка:", err);
    };

    return () => {
      socket.close();
    };
  }, [token]);

const markAsRead = () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    console.warn("❌ Нет токена для markAsRead");
    return;
  }

  // Попытка достать csrfToken из куки
  const csrfMatch = document.cookie.match(/csrftoken=([\w-]+)/);
  const csrfToken = csrfMatch ? csrfMatch[1] : null;

  if (!csrfToken) {
    console.warn("❌ Нет CSRF токена");
    return;
  }

  fetch('/api/notifications/mark-read/', {
    method: 'PATCH',
    headers: {
      Authorization: 'Token ' + token,
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken
    },
    credentials: 'include' // ⬅️ важно для кук!
  })
    .then(res => {
      if (!res.ok) {
        return res.json().then(err => {
          console.error("⛔ PATCH ошибка:", err);
        });
      }
      setNotifications(prev => prev.map(n => ({ ...n, is_read: true })));
    })
    .catch(err => console.error("🔥 Ошибка запроса:", err));
};



  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const unreadCount = notifications.filter(n => !n.is_read).length;

  return (
    <div
      className="notifications"
      ref={dropdownRef}
      style={{ position: 'relative', marginRight: '1rem' }}
    >
      <button onClick={() => setOpen(!open)} style={{ position: 'relative' }}>
        🔔
        {unreadCount > 0 && (
          <span style={{
            position: 'absolute',
            top: '-5px',
            right: '-10px',
            backgroundColor: 'red',
            color: 'white',
            fontSize: '12px',
            padding: '0 4px',
            borderRadius: '50%'
          }}>
            {unreadCount}
          </span>
        )}
      </button>

      {open && (
        <div style={{
          position: 'absolute',
          right: '-50px',
          marginTop: '0.5rem',
          width: '300px',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          zIndex: 100
        }}>
          <div style={{
            padding: '8px',
            borderBottom: '1px solid #eee',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <strong style={{ fontSize: '14px' }}>Уведомления</strong>
            <button onClick={markAsRead} style={{
              backgroundColor: '#eee',
              color: '#333',
              border: 'none',
              padding: '4px 8px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '12px',
              transition: 'background-color 0.2s'
            }}
              onMouseOver={e => e.target.style.backgroundColor = '#ddd'}
              onMouseOut={e => e.target.style.backgroundColor = '#eee'}
            >
              Прочитано
            </button>
          </div>
          <ul style={{ maxHeight: '200px', overflowY: 'auto', margin: 0, padding: 0 }}>
            {notifications.length === 0 ? (
              <li style={{ padding: '8px', color: '#777' }}>Нет уведомлений</li>
            ) : (
              notifications.map(n => (
                <li key={n.id} style={{
                  padding: '8px',
                  borderBottom: '1px solid #eee',
                  fontWeight: n.is_read ? 'normal' : 'bold',
                  color: n.is_read ? '#999' : '#000'
                }}>
                  {n.message}
                  <div style={{ fontSize: '10px', color: '#666' }}>
                    {new Date(n.created_at).toLocaleString()}
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default NotificationsDropdown;
