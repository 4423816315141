// src/pages/RegisterPage.js
import React, { useState, useEffect } from 'react';
import axios from '../axiosSetup';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [step, setStep] = useState('register'); // 'register' | 'confirm' | 'done'
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/ws/auth/`);
    ws.onopen = () => console.log("🟢 WebSocket подключен: auth");
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("📨 WebSocket сообщение:", data);

      if (data.type === "code_sent" && data.email === email) {
        setStep('confirm');
        setMessage("Код подтверждения был отправлен на вашу почту.");
      }
    };
    ws.onclose = () => console.log("🔴 WebSocket отключен");
    setSocket(ws);

    return () => ws.close();
  }, [email]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    if (ref) {
      localStorage.setItem('ref_code', ref);
    }
  }, []);

const handleRegister = async () => {
  setLoading(true);
  setMessage('');
  try {
    const ref_code = localStorage.getItem('ref_code');  // 👈 добавляем
    await axios.post('/accounts/register/', { email, password, ref_code });
    setMessage("⏳ Отправка кода...");
  } catch (error) {
    console.error("Ошибка при регистрации:", error);
    setMessage("Ошибка регистрации. Попробуйте снова.");
  } finally {
    setLoading(false);
  }
};

  const handleConfirm = async () => {
    setLoading(true);
    setMessage('');
    try {
      await axios.post('/accounts/confirm-code/', { email, code: confirmationCode });
      setMessage("✅ Email успешно подтверждён. Выполняется вход...");
      setStep('done');

      // 🔐 Автоматический вход
      setTimeout(() => {
        axios.post('/api/accounts/api-login/', { email, password })

          .then(res => {
            console.log("Авто-вход выполнен:", res.data);
            window.location.href = '/'; // редирект на главную
          })
          .catch(err => {
            console.error("Ошибка при авто-входе:", err);
            setMessage("Email подтверждён, но вход не выполнен. Войдите вручную.");
            setStep('login_error');
          });
      }, 1000);

    } catch (error) {
      console.error("Ошибка при подтверждении кода:", error);
      setMessage("❌ Неверный код подтверждения. Попробуйте снова.");
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    setLoading(true);
    setMessage('');
    try {
      const ref_code = localStorage.getItem('ref_code');  // 👈 ловим сохранённый ранее код
      await axios.post('/accounts/register/', { email, password, ref_code });
      setMessage("🔁 Код отправлен повторно. Проверьте почту.");
    } catch (error) {
      console.error("Ошибка при повторной отправке:", error);
      setMessage("Не удалось отправить код повторно.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Регистрация</h2>

      {step === 'register' && (
        <>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleRegister} disabled={loading}>
            {loading ? "Отправка..." : "Зарегистрироваться"}
          </button>
        </>
      )}

      {step === 'confirm' && (
        <>
          <p>📧 Код отправлен на: {email}</p>
          <input
            type="text"
            placeholder="Введите код подтверждения"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
          <button onClick={handleConfirm} disabled={loading}>
            {loading ? "Проверка..." : "Подтвердить код"}
          </button>
          <br />
          <button onClick={resendCode} disabled={loading}>🔁 Отправить код заново</button>
        </>
      )}

      {message && <p>{message}</p>}
    </div>
  );
};

export default RegisterPage;
