// components/CommunitySelector.js
import React, { useEffect, useState, useRef } from 'react';

const CommunitySelector = ({ communityId }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [adm1List, setAdm1List] = useState([]);
  const [adm2List, setAdm2List] = useState([]);
  const [adm3List, setAdm3List] = useState([]);
  const [selectedAdm1, setSelectedAdm1] = useState('');
  const [selectedAdm2, setSelectedAdm2] = useState('');
  const [selectedAdm3, setSelectedAdm3] = useState('');
  const [currentCommunityName, setCurrentCommunityName] = useState(null);
  const menuRef = useRef();
  const [currentCommunity, setCurrentCommunity] = useState(null);

useEffect(() => {
  fetch('/api/accounts/me_community/')
    .then(res => res.json())
    .then(data => {
      if (data.community) {
        setCurrentCommunity(data.community);
        setSelectedAdm3(data.community.id);
        setSelectedAdm2(data.community.adm2_id);
        setSelectedAdm1(data.community.adm1_id);
      }
    });
}, []);


  useEffect(() => {
    fetch('/api/map/adm1/')
      .then(res => res.json())
      .then(data => setAdm1List(data));
  }, []);

  useEffect(() => {
    if (selectedAdm1) {
      fetch(`/api/map/adm2/?adm1_id=${selectedAdm1}`)
        .then(res => res.json())
        .then(data => setAdm2List(data));
    } else {
      setAdm2List([]);
      setAdm3List([]);
    }
  }, [selectedAdm1]);

  useEffect(() => {
    if (selectedAdm2) {
      fetch(`/api/map/adm3/?adm2_id=${selectedAdm2}`)
        .then(res => res.json())
        .then(data => {
          const communities = data.features.map(f => ({
            id: f.properties.shapeID,
            name: f.properties.shapeName,
            adm2_id: f.properties.ADM2_PCODE,
            adm1_id: f.properties.ADM1_PCODE,
          }));
          setAdm3List(communities);

          if (communityId) {
            const match = communities.find(c => c.id === communityId);
            if (match) {
              setCurrentCommunityName(match.name);
              setSelectedAdm3(match.id);
              setSelectedAdm2(match.adm2_id);
              setSelectedAdm1(match.adm1_id);
            }
          }
        });
    } else {
      setAdm3List([]);
    }
  }, [selectedAdm2, communityId]);

  useEffect(() => {
    // если communityId установлен, найди район, чтобы подгрузить громаду
    if (communityId && !selectedAdm2) {
      fetch(`/api/map/adm3/search/?id=${communityId}`)
        .then(res => res.json())
        .then(data => {
          if (data?.adm2_id && data?.adm1_id) {
            setSelectedAdm2(data.adm2_id);
            setSelectedAdm1(data.adm1_id);
          }
        });
    }
  }, [communityId]);

  const handleSaveCommunity = () => {
  const csrftoken = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];

  fetch('/api/accounts/set_community/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    credentials: 'include',
    body: JSON.stringify({ community_id: selectedAdm3 })
  })
    .then(res => res.json())
    .then(data => {
      const updated = adm3List.find(c => c.id === selectedAdm3);
      if (updated) {
        setCurrentCommunity(updated);
        setCurrentCommunityName(updated.name);
      }
      alert(data.message || 'Громада установлена!');
    })
    .catch(e => alert('Ошибка при сохранении громады.'));
};


return (
  <div style={{ position: 'relative', marginBottom: '20px' }}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '10px',
      maxWidth: '500px',
      flexWrap: 'wrap'
    }}>
      <h4 style={{ margin: 0, color: 'white', whiteSpace: 'nowrap' }}>
        Текущая громада:
      </h4>
      <span style={{
        fontWeight: 'bold',
        color: '#b4ffb4',
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        {currentCommunity?.name || 'Не определена'}
      </span>
      <button
        onClick={() => setMenuOpen(prev => !prev)}
        style={{
          width: '160px',
          padding: '6px 10px',
          fontSize: '1rem',
          backgroundColor: '#2e8b57',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          lineHeight: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
Выбрать громаду
      </button>
    </div>

    {/* 👇 Выпадающее меню — отдельно от строки */}
    {menuOpen && (
      <div
        ref={menuRef}
        style={{
          marginTop: '10px',
          padding: '10px',
          background: '#222',
          border: '1px solid #555',
          borderRadius: '6px',
          width: '300px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
          color: 'white'
        }}
      >
        <label style={{ display: 'block', marginBottom: '8px' }}>
          Область:
          <select
            value={selectedAdm1}
            onChange={e => setSelectedAdm1(e.target.value)}
            style={{ width: '100%', marginTop: '4px' }}
          >
            <option value=''>-- выберите область --</option>
            {adm1List.map(region => (
              <option key={region.id} value={region.id}>{region.name}</option>
            ))}
          </select>
        </label>

        <label style={{ display: 'block', marginBottom: '8px' }}>
          Район:
          <select
            value={selectedAdm2}
            onChange={e => setSelectedAdm2(e.target.value)}
            style={{ width: '100%', marginTop: '4px' }}
            disabled={!selectedAdm1}
          >
            <option value=''>-- выберите район --</option>
            {adm2List.map(district => (
              <option key={district.id} value={district.id}>{district.name}</option>
            ))}
          </select>
        </label>

        <label style={{ display: 'block', marginBottom: '8px' }}>
          Громада:
          <select
            value={selectedAdm3}
            onChange={e => setSelectedAdm3(e.target.value)}
            style={{ width: '100%', marginTop: '4px' }}
            disabled={!selectedAdm2}
          >
            <option value=''>-- выберите громаду --</option>
            {adm3List.map(community => (
              <option key={community.id} value={community.id}>{community.name}</option>
            ))}
          </select>
        </label>

        <button
          onClick={handleSaveCommunity}
          disabled={!selectedAdm3}
          style={{
            backgroundColor: '#2e8b57',
            color: '#fff',
            border: 'none',
            padding: '6px 10px',
            borderRadius: '4px',
            width: '100%',
            cursor: 'pointer',
            marginTop: '10px'
          }}
        >
          ✅ Сохранить громаду
        </button>
      </div>
    )}
  </div>
);


};


export default CommunitySelector;
