// ✅ frontend/src/components/Map.js
// Отображает карту с маркерами, загруженными с API + меню выбора области/района

import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/Map.css';
import MapMenu from './MapMenu';
import useMapSocket from './useMapSocket';
import useMarkerFilter from './useMarkerFilter';



const dumpIcon = L.icon({
  iconUrl: '/icons/dump_marker_40x40.png',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const recycleIcon = L.icon({
  iconUrl: '/icons/recycling_marker_40x40.png',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const Map = ({ userEmail }) => {
  const [locations, setLocations] = useState([]);
  const [map, setMap] = useState(null);
  const [adm1List, setAdm1List] = useState([]);
  const [adm2List, setAdm2List] = useState([]);
  const [selectedAdm1, setSelectedAdm1] = useState('');
  const [selectedAdm2, setSelectedAdm2] = useState('');
  const [communityLayer, setCommunityLayer] = useState(null);
  const [loadingAdm3, setLoadingAdm3] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeCommunityId, setActiveCommunityId] = useState(null);
  const menuRef = useRef(null);
  const [popupLatLng, setPopupLatLng] = useState(null);
  const [showDumps, setShowDumps] = useState(true);
  const [showRecyclingPoints, setShowRecyclingPoints] = useState(true);



  useEffect(() => {
    async function loadLocations() {
      const apiUrl = 'https://ecomaner.com/api/map/locations/';
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (Array.isArray(data)) {
          setLocations(data);
        } else {
          console.error("Некорректный формат данных:", data);
        }
      } catch (error) {
        console.error("Ошибка загрузки:", error);
      }
    }
    loadLocations();
  }, []);

  useEffect(() => {
    if (!map) {
      const newMap = L.map('map').setView([50.27, 30.31], 10);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
      }).addTo(newMap);
      setMap(newMap);
    }
  }, [map]);

  useMarkerFilter({ map, locations, showDumps, showRecyclingPoints, userEmail, getCookie });

  useMapSocket(map, recycleIcon, dumpIcon);


  useEffect(() => {
    fetch('/api/map/adm1/')
      .then(res => res.json())
      .then(data => setAdm1List(data))
      .catch(err => console.error("Ошибка загрузки областей:", err));
  }, []);

  useEffect(() => {
    if (!selectedAdm1) return;
    fetch(`/api/map/adm2/?adm1_id=${selectedAdm1}`)
      .then(res => res.json())
      .then(data => setAdm2List(data))
      .catch(err => console.error("Ошибка загрузки районов:", err));
  }, [selectedAdm1]);

  useEffect(() => {
  function handleClickOutside(event) {
    // если клик был вне меню и не по кнопке
    const isOutsideClick = menuRef.current && !menuRef.current.contains(event.target);
    const isToggleButton = event.target.closest("button")?.textContent?.includes("Меню карты");

    if (isOutsideClick && !isToggleButton) {
      setMenuOpen(false);
    }
  }

  if (menuOpen) {
    document.addEventListener("mousedown", handleClickOutside);
  } else {
    document.removeEventListener("mousedown", handleClickOutside);
  }

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [menuOpen]);


  const handleLoadAdm3 = () => {
    if (!map || !selectedAdm2) return;

    setLoadingAdm3(true);

    fetch(`/api/map/adm3/?adm2_id=${selectedAdm2}`)
      .then(res => res.json())
      .then(geoData => {
        if (communityLayer) {
          map.removeLayer(communityLayer);
        }

        const newLayer = L.geoJSON(geoData, {
          style: feature => ({
            color: '#444',
            weight: 1,
            fillColor: feature.properties.shapeID === activeCommunityId ? '#2e8b57' : '#99ccff',
            fillOpacity: feature.properties.shapeID === activeCommunityId ? 0.4 : 0.1,
          }),
          onEachFeature: (feature, layer) => {
            const name = feature.properties.shapeName;
            const id = feature.properties.shapeID;
            layer.bindPopup(`<b>${name}</b><br/><i>ID: ${id}</i>`);
            layer.on('click', (e) => {
  setActiveCommunityId(id);
  setPopupLatLng(e.latlng); // 💾 сохраняем координаты клика
});

          }
        });

        newLayer.addTo(map);
        setCommunityLayer(newLayer);
        map.fitBounds(newLayer.getBounds());
      })
      .catch(err => console.error('Ошибка загрузки ADM3:', err))
      .finally(() => setLoadingAdm3(false));
  };

// 🔁 Обновляем стиль активной громады при изменении activeCommunityId
useEffect(() => {
  if (!map || !communityLayer || !selectedAdm2 || !activeCommunityId) return;

  fetch(`/api/map/adm3/?adm2_id=${selectedAdm2}`)
    .then(res => res.json())
    .then(geoData => {
      map.removeLayer(communityLayer);

      let activeFeatureLayer = null;

      const newLayer = L.geoJSON(geoData, {
        style: feature => ({
  color: '#444',
  weight: feature.properties.shapeID === activeCommunityId ? 4 : 1,
  fillOpacity: 0,  // ⬅️ полностью прозрачный фон
})
,

        onEachFeature: (feature, layer) => {
          const name = feature.properties.shapeName;
          const id = feature.properties.shapeID;
          layer.bindPopup(`<b>${name}</b><br/><i>ID: ${id}</i>`);
          
          // Сохраняем слой, если он активный
          if (id === activeCommunityId) {
            activeFeatureLayer = layer;
          }

          layer.on('click', (e) => {
            setActiveCommunityId(id);
            setPopupLatLng(e.latlng);  // 💾 сохраняем координаты
          });
        }
      });

      newLayer.addTo(map);
      setCommunityLayer(newLayer);

      // ✅ Открываем popup заново
      if (popupLatLng && activeFeatureLayer) {
  	const feature = geoData.features.find(f => f.properties.shapeID === activeCommunityId);

  	if (feature) {
    		const name = feature.properties.shapeName;
    		const popupContent = `<b>${name}</b><br/><i>ID: ${activeCommunityId}</i>`;
    		L.popup()
      		.setLatLng(popupLatLng)
      		.setContent(popupContent)
      		.openOn(map);
  }
}

    })
    .catch(err => console.error("Ошибка обновления подсветки:", err));
}, [activeCommunityId]);



return (
  <>
    <div id="map" style={{ height: '600px', width: '100%', position: 'relative' }}>
      <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1000
      }}>
        <button
          onClick={() => setMenuOpen(prev => !prev)}
          style={{
            backgroundColor: '#2e8b57',
            color: '#fff',
            border: 'none',
            padding: '6px 10px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          🛠 Меню карты
        </button>

        {menuOpen && (
          <MapMenu
            selectedAdm1={selectedAdm1}
            selectedAdm2={selectedAdm2}
            setSelectedAdm1={setSelectedAdm1}
            setSelectedAdm2={setSelectedAdm2}
            adm1List={adm1List}
            adm2List={adm2List}
            showDumps={showDumps}
            showRecyclingPoints={showRecyclingPoints}
            setShowDumps={setShowDumps}
            setShowRecyclingPoints={setShowRecyclingPoints}
            handleLoadAdm3={handleLoadAdm3}
            loadingAdm3={loadingAdm3}
            menuRef={menuRef}
          />
        )}
      </div>
    </div>
  </>
);




};

export default Map;
