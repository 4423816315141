// LocationDetails.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const LocationDetails = () => {
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchLocation() {
      try {
        const response = await fetch(`https://ecomaner.com/api/map/locations/${id}/`);
        if (!response.ok) throw new Error('Ошибка при получении данных');
        const data = await response.json();
        setLocation(data);
      } catch (err) {
        setError(err.message);
      }
    }
    fetchLocation();
  }, [id]);

  if (error) return <div>Ошибка: {error}</div>;
  if (!location) return <div>Загрузка...</div>;

  return (
    <div style={{ padding: '20px' }}>
      <h2>Локация #{location.id}</h2>
      <p><b>Тип:</b> {location.type}</p>
      <p><b>Описание:</b> {location.description}</p>
      <p><b>Адрес:</b> {location.address}</p>
      <p><b>Статус:</b> {location.status}</p>
      <p><b>Игроков участвовало:</b> {location.players_ids?.length || 0}</p>

      <h3>Фотографии:</h3>
      {location.photos.length === 0 && <p>Нет фото</p>}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
        {location.photos.map((photo) => (
          <div key={photo.id} style={{ maxWidth: '160px' }}>
            <img
              src={photo.image_url}
              alt="Фото"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.3)' }}
            />
            <p style={{ fontSize: '12px', color: 'gray' }}>от: {photo.uploaded_by}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocationDetails;
