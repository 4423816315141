// frontend/src/components/useMapSocket.js

import { useEffect } from 'react';
import L from 'leaflet';

const useMapSocket = (map, recycleIcon, dumpIcon) => {
  useEffect(() => {
    if (!map) return;

    const socket = new WebSocket('wss://ecomaner.com/ws/map/updates/');

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === 'new_location') {
        const location = message.location;
        const lat = parseFloat(location.latitude);
        const lng = parseFloat(location.longitude);

        if (!isNaN(lat) && !isNaN(lng)) {
          const icon = location.type === 'recycling_point' ? recycleIcon : dumpIcon;

          const popupContent = `
            <div style="max-width: 250px;">
              <b>Тип:</b> ${location.type || 'Не указан'}<br/>
              <b>Адрес:</b> ${location.address || 'Неизвестен'}<br/>
              <b>Фото:</b> ${location.photos?.length || 0}<br/>
              <b>Пользователей:</b> ${new Set(location.photos?.map(p => p.uploaded_by)).size || 0}<br/>
              <br/>
              <a href="/location/${location.id}" target="_blank" style="text-decoration: none; color: #2e8bff;">
                🔍 Открыть локацию
              </a>
            </div>
          `;

          L.marker([lat, lng], { icon }).addTo(map).bindPopup(popupContent);
        }
      }
    };

    return () => socket.close();
  }, [map, recycleIcon, dumpIcon]);
};

export default useMapSocket;
