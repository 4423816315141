// src/axiosSetup.js
import axios from 'axios';

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

const instance = axios.create({
  baseURL: 'https://ecomaner.com/api',
  withCredentials: true, // обязательно для отправки куков
  headers: {
    'Content-Type': 'application/json'
  }
});

// 🎯 Автоматически добавляем токены ко всем запросам
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken');
  const csrfToken = getCookie('csrftoken');

  if (token) config.headers['Authorization'] = `Token ${token}`;
  if (csrfToken) config.headers['X-CSRFToken'] = csrfToken;

  return config;
});

export default instance;
