// frontend/src/components/MapMenu.js

import React from 'react';

const MapMenu = ({
  selectedAdm1,
  selectedAdm2,
  setSelectedAdm1,
  setSelectedAdm2,
  adm1List,
  adm2List,
  showDumps,
  showRecyclingPoints,
  setShowDumps,
  setShowRecyclingPoints,
  handleLoadAdm3,
  loadingAdm3,
  menuRef,
}) => {
  return (
    <div
      ref={menuRef}
      style={{
        marginTop: '10px',
        padding: '10px',
        background: '#222',
        border: '1px solid #555',
        borderRadius: '6px',
        width: '250px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
        color: 'white',
      }}
    >
      <label style={{ display: 'block', marginBottom: '8px' }}>
        Область:
        <select
          value={selectedAdm1}
          onChange={e => setSelectedAdm1(e.target.value)}
          style={{ width: '100%', marginTop: '4px' }}
        >
          <option value=''>-- выберите область --</option>
          {adm1List.map(region => (
            <option key={region.id} value={region.id}>{region.name}</option>
          ))}
        </select>
      </label>

      <label style={{ display: 'block', marginBottom: '8px' }}>
        Район:
        <select
          value={selectedAdm2}
          onChange={e => setSelectedAdm2(e.target.value)}
          style={{ width: '100%', marginTop: '4px' }}
        >
          <option value=''>-- выберите район --</option>
          {adm2List.map(district => (
            <option key={district.id} value={district.id}>{district.name}</option>
          ))}
        </select>
      </label>

      <button
        onClick={handleLoadAdm3}
        style={{
          backgroundColor: '#2e8b57',
          color: '#fff',
          border: 'none',
          padding: '6px 10px',
          borderRadius: '4px',
          width: '100%',
          cursor: 'pointer',
          marginBottom: '12px'
        }}
      >
        Показать громады
      </button>

      {loadingAdm3 && (
        <div className="adm3-loading-spinner" style={{ marginBottom: '10px' }}>
          <div className="spinner"></div>
          <span>Загрузка громады...</span>
        </div>
      )}

      <label style={{ display: 'block', marginBottom: '6px' }}>
        <input
          type="checkbox"
          checked={showDumps}
          onChange={(e) => setShowDumps(e.target.checked)}
          style={{ marginRight: '6px' }}
        />
        Свалки
      </label>

      <label style={{ display: 'block' }}>
        <input
          type="checkbox"
          checked={showRecyclingPoints}
          onChange={(e) => setShowRecyclingPoints(e.target.checked)}
          style={{ marginRight: '6px' }}
        />
        Места сбора
      </label>
    </div>
  );
};

export default MapMenu;
