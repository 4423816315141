// frontend/src/components/AccountPage.js

import React, { useEffect, useState } from 'react';
import axios from '../axiosSetup';
import CommunitySelector from '../components/CommunitySelector';


const AccountPage = ({ onLogout }) => {
  const [userData, setUserData] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [referrals, setReferrals] = useState([]);
  const [copied, setCopied] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userRes, balanceRes, supplyRes, referralsRes] = await Promise.all([
          axios.get('/accounts/profile/'),
          axios.get('/rewards/balance/'),
          axios.get('/rewards/supply/'),
          axios.get('/accounts/referrals/')
        ]);
        setUserData(userRes.data);
        setUserBalance(balanceRes.data.balance);
        setTotalSupply(supplyRes.data.total_supply);
        setReferrals(referralsRes.data);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      }
    };

    fetchData();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://ecomaner.com/register?ref=${userData.ref_code}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

const getCSRFToken = () => {
  const match = document.cookie.match(/csrftoken=([^;]+)/);
  return match ? match[1] : null;
};

const handleSaveName = async () => {
  const csrfToken = getCSRFToken();
  if (!csrfToken) {
    alert("Ошибка: CSRF-токен отсутствует.");
    return;
  }

  try {
    await axios.patch(
      "/accounts/profile/",
      { display_name: userData.display_name },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken
        }
      }
    );
    alert("Имя обновлено");
  } catch (error) {
    console.error("Ошибка при обновлении имени:", error);
    alert("Ошибка при обновлении имени");
  }
};





  if (!userData) return <p>Загрузка...</p>;

  return (
    <div>

<div style={{
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px'
}}>
  <h2 style={{ margin: 0, flexGrow: 1 }}>Личный кабинет</h2>
  <div style={{ flexShrink: 0 }}>
    <button
      onClick={onLogout}
      style={{
        width: '100px', // фиксированная ширина
        background: '#333',
        color: '#fff',
        border: '1px solid #666',
        borderRadius: '4px',
        padding: '4px 10px',
        fontSize: '0.85rem',
        cursor: 'pointer'
      }}
    >
      ⏻ Выйти
    </button>
  </div>
</div>


<div style={{
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '20px',
  maxWidth: '500px' // ограничение ширины всей строки
}}>
  <label htmlFor="displayName">Имя:</label>
  <input
    id="displayName"
    type="text"
    value={userData.display_name || ""}
    onChange={(e) => setUserData({ ...userData, display_name: e.target.value })}
    placeholder="Ваше имя"
    style={{
      padding: '4px',
      fontSize: '0.9rem',
      width: '100%',         // важно: вписывается в ограниченный контейнер
      maxWidth: '300px',     // ограничение ширины поля
    }}
  />
  <button
    onClick={handleSaveName}
    title="Сохранить"
    style={{
      width: '100px',
      padding: '4px 8px',
      fontSize: '1.2rem',
      backgroundColor: '#2e8b57',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      lineHeight: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    Сохранить
  </button>
</div>






      <p>Email: {userData.email || "Email не указан"}</p>

<div>
  <h2>Моя громада</h2>
  <CommunitySelector communityId={userData.community_id} />

</div>

      <hr />

      <h3>💰 Баланс токенов</h3>
      <p><strong>Ваш баланс:</strong> {userBalance ?? "Загрузка..."} ECOMNR</p>
      <p><strong>Остаток в фонде:</strong> {totalSupply ?? "Загрузка..."} ECOMNR</p>

      <hr />

      <h3>🤝 Реферальная программа</h3>
      <p><strong>Ваша ссылка для приглашений:</strong></p>

      <div
        onClick={handleCopy}
        title="Нажмите, чтобы скопировать ссылку"
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          background: '#222',
          padding: '6px 8px',
          borderRadius: '4px',
          gap: '6px',
          marginBottom: '10px',
          maxWidth: '100%',
          overflowX: 'auto',
          cursor: 'pointer'
        }}
      >
        <code style={{
          fontSize: '0.9rem',
          color: '#eee',
          whiteSpace: 'nowrap',
          wordBreak: 'break-all'
        }}>
          {`https://ecomaner.com/register?ref=${userData.ref_code}`}
        </code>

        <span style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '24px',
          width: '24px',
          fontSize: '16px'
        }}>
          📋
        </span>
      </div>

      {copied && (
        <p style={{ color: 'limegreen', fontSize: '0.8rem', marginTop: '5px' }}>
          Скопировано!
        </p>
      )}

      <h4>👥 Приглашённые пользователи:</h4>
      {referrals.length > 0 ? (
        <ul>
          {referrals.map((r, i) => (
            <li key={i}>
              {r.referred_name} — <small>{new Date(r.created_at).toLocaleDateString()}</small>
            </li>
          ))}
        </ul>
      ) : (
        <p>Пока никого не пригласили.</p>
      )}
    </div>
  );
};

export default AccountPage;
