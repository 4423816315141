// frontend/src/pages/ContactPage.js

import React, { useEffect, useState } from 'react';
import '../styles/Contact.css';

const ContactPage = () => {
  const [canInstall, setCanInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setCanInstall(true);
      setMessage('📲 Вы можете установить приложение');
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        setMessage('✅ Приложение успешно установлено!');
      } else {
        setMessage('❌ Установка отменена.');
      }

      setDeferredPrompt(null);
      setCanInstall(false);
    });
  };

  return (
    <main className="contact">
      <header>
        <h1>Экоманер</h1>
        <h2>Контакты</h2>
        <p>
          Свяжитесь с нами по электронной почте или в социальных сетях. Мы стремимся отвечать на все ваши вопросы и стараемся обрабатывать каждое сообщение как можно быстрее. Ваше мнение и идеи важны для нас!
        </p>
      </header>

      <section className="section">
        <h3>Наши контакты</h3>

        <div className="contact-info">
          <p><strong>Email:</strong> <a href="mailto:ecomaner@gmail.com">ecomaner@gmail.com</a></p>
        </div>

        <div className="contact-info">
          <p><strong>Twitter:</strong> <a href="https://x.com/ecomaner" target="_blank" rel="noopener noreferrer">https://x.com/ecomaner</a></p>
        </div>

        <div className="contact-info">
          <p><strong>Discord:</strong> <a href="https://discord.com/channels/935569452861370449/935569452861370452" target="_blank" rel="noopener noreferrer">Присоединиться к нашему Discord</a></p>
        </div>
      </section>

      {/* 🔹 Кнопка установки PWA */}
      <button
        onClick={handleInstallClick}
        disabled={!canInstall}
        style={{
          padding: "10px",
          marginTop: "20px",
          backgroundColor: canInstall ? "#4CAF50" : "#999",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: canInstall ? "pointer" : "not-allowed",
          opacity: canInstall ? 1 : 0.7,
          transition: "all 0.3s"
        }}
      >
        📲 Установить приложение
      </button>

      {/* 🔔 Сообщение */}
      {message && (
        <p style={{ marginTop: '10px', fontStyle: 'italic', color: '#ffc107' }}>
          {message}
        </p>
      )}
    </main>
  );
};

export default ContactPage;
